var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "about" }, [
    _c(
      "div",
      { staticClass: "container" },
      _vm._l(_vm.content, function (category) {
        return _c(
          "div",
          { key: category.id, staticClass: "about__section" },
          [
            _c("h1", { staticClass: "about__section-title" }, [
              _vm._v(_vm._s(category.title)),
            ]),
            _vm._l(category.chapters, function (chapter) {
              return _c(
                "div",
                { key: chapter.title },
                [
                  _c("h2", { staticClass: "about__section-subtitle" }, [
                    _vm._v(" " + _vm._s(chapter.title) + " "),
                  ]),
                  _vm._l(chapter.items, function (document) {
                    return _c(_vm.currentComponent(category.id), {
                      key: document.title,
                      tag: "Component",
                      attrs: { document: document },
                    })
                  }),
                ],
                2
              )
            }),
          ],
          2
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }