var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "aboutContact" }, [
    _c("div", { staticClass: "aboutContact__text" }, [
      _vm.document.type === "phone"
        ? _c(
            "a",
            {
              staticClass: "aboutContact__text-link mod-phone",
              attrs: { href: `tel:${_vm.document.link}` },
            },
            [_c("div", [_vm._v(_vm._s(_vm.document.title))])]
          )
        : _c(
            "a",
            {
              staticClass: "aboutContact__text-link mod-blue",
              attrs: { href: `mailto:${_vm.document.title}` },
            },
            [_c("div", [_vm._v(_vm._s(_vm.document.title))])]
          ),
      _c("div", [_vm._v("-")]),
      _c("div", [_vm._v(_vm._s(_vm.document.subtitle))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }