<template>
  <div class="about">
    <div class="container">
      <div
        v-for="category in content"
        :key="category.id"
        class="about__section"
      >
        <h1 class="about__section-title">{{ category.title }}</h1>

        <div v-for="chapter in category.chapters" :key="chapter.title">
          <h2 class="about__section-subtitle">
            {{ chapter.title }}
          </h2>

          <Component
            :is="currentComponent(category.id)"
            v-for="document in chapter.items"
            :key="document.title"
            :document="document"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { aboutContent } from '@/views/about/data'
import AboutContact from '@/views/about/components/AboutContact.vue'
import AboutDocument from './components/AboutDocument.vue'

export default {
  name: 'About',
  components: { AboutDocument, AboutContact },
  data() {
    return {
      content: aboutContent,
    }
  },
  methods: {
    currentComponent(category) {
      return category === 'documents' ? AboutDocument : AboutContact
    },
  },
}
</script>

<style lang="sass" scoped>
.about
  padding: 40px 0

  &__section
    margin-bottom: 60px

    &-title
      font-size: 26px
      margin: 0 0 26px
      font-weight: bold

    &-subtitle
      font-size: 20px
      margin: 0 0 20px
      font-weight: 700
      color: $fontColor
</style>
