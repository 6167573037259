export const aboutContent = [
  {
    id: 'documents',
    title: 'Документы и ссылки',
    chapters: [
      {
        title: 'Администраторам системы:',
        items: [
          {
            title: '"Памятка ЭО: Основные функции и настройки"',
            subtitle:
              'памятка для основных необходимых функций и настроек системы',
            link: 'https://disk.yandex.ru/i/DsZNErBxfuFwoQ',
          },
          {
            title: '"Инструкция ЭО: Квотирование и управление"',
            subtitle:
              'инструкция по квотированию и использованию раздела "Управление"',
            link: 'https://disk.yandex.ru/i/s5TxBpnbaEMpSg',
          },
          {
            title: '"Инструкция ЭО: Правила вызова авто"',
            subtitle:
              'инструкция по назначению правил вызова (автоматизация) авто с парковки',
            link: 'https://disk.yandex.ru/i/Rfknc44W7Jh8Dg',
          },
          {
            title: '"Инструкция ЭО: Раздел Управление"',
            subtitle: 'инструкция по использованию раздела "Управление"',
            link: 'https://disk.yandex.ru/i/aYAXvkz9FOfuLQ',
          },
        ],
      },

      {
        title: 'Поставщикам и водителям:',
        items: [
          {
            title: '"Пользовательская инструкция для веб-приложения (сайта)"',
            subtitle:
              'инструкция по бронированию и использованию тайм-слотов в веб-приложении (на сайте)',
            link: 'https://disk.yandex.ru/i/jTPONyBX9zloAg',
          },
          {
            title: '"Пользовательская инструкция для мобильного приложения"',
            subtitle:
              'инструкция по бронированию и использованию тайм-слотов в приложении',
            link: 'https://disk.yandex.ru/i/1gThOq3UDbEQSA',
          },
        ],
      },
    ],
  },

  {
    id: 'contacts',
    title: 'Полезные контакты',
    chapters: [
      {
        title: 'Администраторам системы:',
        items: [
          {
            title: `+7 (918) 539-83-72`,
            type: 'phone',
            subtitle:
              'Александр Каташевский, менеджер отдела сопровождения терминалов',
            link: '+79185398372',
          },
          {
            title: '+7 (911) 710-35-23',
            type: 'phone',
            subtitle:
              'Артур Владимиров, руководитель отдела сопровождения терминалов',
            link: '+79117103523',
          },
          {
            title: 'avladimirov@port-tranzit.ru',
            type: 'email',
            subtitle: 'для пожеланий и вопросов',
          },
        ],
      },

      {
        title: 'Поставщикам и водителям:',
        items: [
          {
            title: '8 (800) 250-75-31',
            type: 'phone',
            subtitle: 'горячая линия поддержки для пользователей',
            link: '+78002507531',
          },
          {
            title: 'invoice.iq@port-tranzit.ru',
            type: 'email',
            subtitle: 'для отправки платежных поручений при оплате по счету',
          },
        ],
      },
    ],
  },
]
