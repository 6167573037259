<template>
  <div class="aboutContact">
    <div class="aboutContact__text">
      <a
        v-if="document.type === 'phone'"
        :href="`tel:${document.link}`"
        class="aboutContact__text-link mod-phone"
      >
        <div>{{ document.title }}</div>
      </a>
      <a
        v-else
        :href="`mailto:${document.title}`"
        class="aboutContact__text-link mod-blue"
      >
        <div>{{ document.title }}</div>
      </a>
      <div>-</div>
      <div>{{ document.subtitle }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutContact',
  props: {
    document: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<style lang="sass" scoped>
.aboutContact
  display: flex
  justify-content: space-between
  align-items: center
  column-gap: 60px
  margin-left: 20px
  margin-bottom: 20px
  font-size: 18px
  .aboutContact__text
    display: flex
    flex-wrap: wrap
    gap: 8px
    &-link
      display: block
      text-decoration: none
      font-weight: 700
      ::v-deep small
        opacity: 1 !important
    .mod-phone
      color: $color-orange-primary
    .mod-blue
      color: $color-blue
</style>
